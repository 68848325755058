* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  background: linear-gradient(45deg, #F9DBBD, #FFA5AB, #DA627D, #A53860);
  background-size: 1000% 1000%;
  animation: colors 15s ease infinite;
}

@keyframes colors {
  0%{
    background-position: 0% 50%;
  }
  50%{
    background-position: 100% 50%;
  }
  100%{
    background-position: 0% 50%;
  }
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}